<template>
  <div class="home" style="opacity: 1; transform: none">
    <div v-if="!showIframe">
      <div class="home-banner">
        <VueSlickCarousel ref="homeBanner" v-bind="settingHomeBanner">
          <div class="item-carousel">
            <img
              src="~@/assets/images/home/123betnow_banner1.png"
              alt=""
              class="img-banner1"
            />
            <div class="text-banner">
              <p>
                {{ $t('MainContent.118') }}
                <img src="~@/assets/images/home/1.png" alt="" />
                <img src="~@/assets/images/home/2.png" alt="" />
                <img src="~@/assets/images/home/3.png" alt="" />
              </p>
            </div>
            <button @click="showVM555Iframe">
              <img
                src="~@/assets/images/home/button_john.png"
                alt=""
                class="btn-join1"
              />
            </button>
          </div>
          <div class="item-carousel">
            <img
              src="~@/assets/images/home/123betnow_banner2.png"
              alt=""
              class="img-banner2"
            />
            <div class="text-banner2">
              <p>
                {{ $t('MainContent.119') }}:
                <span
                  >{{ $t('MainContent.120') }}</span
                >
              </p>
            </div>
            <button @click="showVM555Iframe">
              <img
                src="~@/assets/images/home/button_john.png"
                alt=""
                class="btn-join2"
              />
            </button>
          </div>
          <div class="item-carousel" v-if="false">
            <img
              src="~@/assets/images/home/banner/golden_eng.png"
              alt=""
              class="img-banner1"
            />
            <div class="text-banner3">
              <img src="~@/assets/images/logo.png" alt="" />
              <img
                src="~@/assets/images/home/banner/golden_comisson.png"
                alt=""
              />
              <p>
                {{ $t('MainContent.121') }}
              </p>
              <img src="~@/assets/images/home/banner/line.png" alt="" />
              <div class="time">
                <img src="~@/assets/images/home/banner/dot.png" alt="" /><span
                  >{{ $t('MainContent.122') }}:</span
                ><img src="~@/assets/images/home/banner/time1.png" alt="" />
              </div>
              <router-link :to="{ name: 'Member' }">
                <button class="text-banner3-btn">
                  <img src="~@/assets/images/home/banner/join_now.png" alt="" />
                </button>
              </router-link>
            </div>
          </div>
          <div class="item-carousel">
            <img
              src="~@/assets/images/home/banner/newbie_eng.png"
              alt=""
              class="img-banner1"
            />
            <div class="text-banner4">
              <img src="~@/assets/images/logo.png" alt="" />
              <img src="~@/assets/images/home/banner/newbie_event.png" alt="" />
              <img src="~@/assets/images/home/banner/line.png" alt="" />
              <div class="time">
                <img src="~@/assets/images/home/banner/dot.png" alt="" />
                <div class="time-text">
                  {{ $t('Transaction.59') }} <span>15%</span> {{ $t('MainContent.123') }} <span>blackchip</span>
                </div>
              </div>
              <div class="time">
                <img src="~@/assets/images/home/banner/dot.png" alt="" />
                <div class="time-text">
                  {{ $t('MainContent.122') }}: <span>{{ $t('Message.265') }}</span>
                </div>
              </div>
              <a v-if="!isLogin" @click="showLogin">
                <button class="text-banner3-btn">
                  <img src="~@/assets/images/home/banner/join_now.png" alt="" />
                </button>
              </a>
              <a v-else @click="$bvModal.show('modal-transaction-home')">
                <button class="text-banner3-btn">
                  <img src="~@/assets/images/home/banner/join_now.png" alt="" />
                </button>
              </a>
            </div>
          </div>
          <div class="item-carousel" v-if="false">
            <img
              src="~@/assets/images/home/banner/talented_eng.png"
              alt=""
              class="img-banner1"
            />
            <div class="text-banner5">
              <img src="~@/assets/images/logo.png" alt="" />
              <img src="~@/assets/images/home/banner/talented.png" alt="" />
              <img src="~@/assets/images/home/banner/line.png" alt="" />
              <div class="time">
                <img src="~@/assets/images/home/banner/dot.png" alt="" />
                {{ $t('MainContent.124') }}:
                <img src="~@/assets/images/home/banner/price1.png" alt="" />
              </div>
              <div class="time">
                <img
                  src="~@/assets/images/home/banner/dot.png"
                  alt=""
                />{{ $t('MainContent.122') }}:<img
                  src="~@/assets/images/home/banner/time1.png"
                  alt=""
                />
              </div>
              <router-link :to="{ name: 'TAgency' }">
                <button class="text-banner3-btn">
                  <img
                    src="~@/assets/images/home/banner/dont_miss.png"
                    alt=""
                  />
                </button>
              </router-link>
            </div>
          </div>
          <router-link :to="{ name: 'TTrader' }" v-if="false">
            <div class="item-carousel">
              <img
                src="~@/assets/images/home/banner/trader_eng.png"
                alt=""
                class="img-banner1"
              />
              <div class="text-banner6">
                <img src="~@/assets/images/logo.png" alt="" />
                <img src="~@/assets/images/home/banner/top_trader.png" alt="" />
                <img src="~@/assets/images/home/banner/line.png" alt="" />
                <div class="time">
                  <img src="~@/assets/images/home/banner/dot.png" alt="" />
                  {{ $t('MainContent.124') }}:
                  <img src="~@/assets/images/home/banner/price2.png" alt="" />
                </div>
                <div class="time">
                  <img
                    src="~@/assets/images/home/banner/dot.png"
                    alt=""
                  />{{ $t('MainContent.122') }}:<img
                    src="~@/assets/images/home/banner/time1.png"
                    alt=""
                  />
                </div>
                <div class="time">
                  <img
                    src="~@/assets/images/home/banner/dot.png"
                    alt=""
                  />{{ $t('MainContent.125') }}
                  <img src="~@/assets/images/home/banner/text_5.png" alt="" />
                </div>
                <!-- <router-link :to="{ name: 'TTrader' }">
                <button class="text-banner3-btn">
                  <img src="~@/assets/images/home/banner/join_now.png" alt="" />
                </button>
              </router-link> -->
              </div>
            </div>
          </router-link>

          <template #customPaging="">
            <div class="custom-dot"></div>
          </template>
        </VueSlickCarousel>
      </div>
      <div class="home-hotbanner">
        <button class="prev-button" @click="showPrevHomeBanner">
          <i class="fas fa-chevron-left"></i>
        </button>
        <button class="next-button" @click="showNextHomeBanner">
          <i class="fas fa-chevron-right"></i>
        </button>
        <!-- Hot banner -->
        <div class="home-hotbanner-box">
          <div class="hotbanner-title">
            <i class="fab fa-galactic-republic"></i>
            <span class="sub-tit">{{ $t('MainContent.126') }}</span
            ><i class="fab fa-galactic-republic"></i>
          </div>
          <VueSlickCarousel ref="homeHotBanner" v-bind="settingHotBanner">
            <div class="pc-wrap">
              <a>
                <img src="~@/assets/images/home/banner_sportbook.png" alt="" />
              </a>
              <div class="text-banner3">
                <p>
                  {{ $t('MainContent.127') }}
                  <span>blackchip</span> {{ $t('MainContent.128') }}
                </p>
                <p>
                  <span>{{ $t('MainContent.129') }}</span>
                  <span>{{ $t('MainContent.130') }}</span>
                </p>
                <p>
                  <span>{{ $t('MainContent.131') }}</span>
                  <span>{{ $t('MainContent.132') }}</span>
                </p>
                <p>
                  <span>{{ $t('MainContent.133') }}</span>
                  <span>{{ $t('MainContent.134') }}</span>
                </p>
              </div>
              <button @click="showSportBookIframe">
                <img
                  src="~@/assets/images/home/button_john.png"
                  alt=""
                  class="btn-join"
                />
              </button>
            </div>
          </VueSlickCarousel>
        </div>
        <!-- Game list -->
        <div
          class="gameList"
          v-if="
            listGame &&
              listGame.game &&
              listGame.game.all &&
              listGame.game.all.length > 0
          "
        >
          <div class="gameItem" v-for="(item, i) in listGame.game.all" :key="i">
            <ItemGame :item="item" @chooseGameGetUrl="chooseGameGetUrl" :type="'home'"/>
          </div>
        </div>
        <!-- Top player -->
        <!-- <div id="home-bigwin" class="home-bigwin">
        <div class="home-bigwin-title">
          Người chiến thắng nhiều nhất trong ngày hôm nay
        </div>
        <div class="home-bigwin-content">
          <div class="bigwin-content-left">
            <div class="info">
              <div class="tit">WINNER WINNER<br />CHICKEN DINNER</div>
              <button
                class="sc-kDTinF sc-ksdxgE jhEfFU gxHTmg button button-normal"
              >
                <div class="button-inner">Chơi ngay</div>
              </button>
            </div>
            <img
              class="bg"
              alt="bg"
              src="https://static.bc.game/static/media/biggest.9872e6fa.png"
            />
          </div>
          <div class="bigwin-content-right">
            <img
              class="winner-img"
              src="~@/assets/images/home/topWinner.png"
              alt=""
            />
            <div class="rich-item" v-for="i in 3" :key="i">
              <div class="user-number">
                <img
                  src="https://static.bc.game/static/media/gold.b279ef91.svg"
                  class="winner-trophy"
                /><span class="user-info rich-user"
                  ><img
                    class="avatar"
                    src="https://static.bc.game/static/media/avatar.b1771de6.png"
                  />
                  <div class="name">
                    <span class="hidden-name"
                      ><i class="fas fa-eye-slash"></i>Hidden</span
                    >
                  </div></span
                >
              </div>
              <div class="top-winner">Top người chiến thắng</div>
              <div class="select rich_select">
                <div class="select-trigger">
                  <div class="win">+68.21480<span>XMR</span></div>
                  <div class="arrow">
                    <i class="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
        <!-- New competition -->
        <!-- <div class="competition">
        <div class="competition-title">
          <div
            class="competition-title-button"
            :class="activeCompetiton === 1 ? 'activeTitle' : ''"
            @click="activeCompetiton = 1"
          >
            Cược mới nhất
          </div>
          <div
            class="competition-title-button"
            :class="activeCompetiton === 2 ? 'activeTitle' : ''"
            @click="activeCompetiton = 2"
          >
            Cuộc thi
          </div>
        </div>
        <div class="competition-content">
          <div class="table-responsive" v-if="activeCompetiton === 1">
            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
              <table
                class="
                  table table-striped table-vcenter
                  js-dataTable-simple
                  dataTable
                  no-footer
                  table-hover
                  font-size-sm
                "
              >
                <thead>
                  <tr>
                    <th>Trò chơi</th>
                    <th>Người chơi</th>
                    <th>ID cược</th>
                    <th>Thanh toán</th>
                    <th>Lợi nhuận</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="i in 10" :key="i">
                    <td>
                      <img
                        src="~@/assets/images/home/itemCompetition.png"
                        alt=""
                      />
                      Limbo
                    </td>
                    <td><i class="fas fa-eye-slash"></i>Hidden</td>
                    <td>37852607636604780</td>
                    <td>1.20x</td>
                    <td>
                      <img src="~@/assets/images/home/USDT.png" alt="" />
                      0.00040960
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <template v-if="activeCompetiton === 2">
            <div class="competition-main">
              <div class="competition-main-left">
                <div class="trophy">
                  <img
                    src="https://static.bc.game/static/media/trophy.ecca2520.png"
                  />
                </div>
                <div class="trophy-content">
                  <div class="type ttc">daily</div>
                  <div class="title">Tổng giải thưởng của cuộc thi</div>
                  <div class="prize-amount">
                    <img
                      class="currency-icon"
                      src="~@/assets/images/home/coin.png"
                    /><span>0.06590850</span
                    ><span class="currency-name"> BTC</span>
                  </div>
                </div>
              </div>
              <div class="competition-main-right">
                <div class="text">
                  Ai là nhà vô địch bất khả chiến bại ở bảng trên? Hãy xem bạn
                  có thể trở thành một kẻ xấu xa đến mức nào!
                </div>
                <button class="button button-normal breathe">
                  <div class="button-inner">Tham gia ngay</div>
                </button>
              </div>
            </div>
            <div class="competition-main-table">
              <div class="title">
                <div class="active-icon">
                  <div class="label">Hoạt động</div>
                </div>
                <div class="time">11/5/2021 ~ 11/6/2021</div>
              </div>
              <div class="table-responsive">
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <table
                    class="
                      table table-striped table-vcenter
                      js-dataTable-simple
                      dataTable
                      no-footer
                      table-hover
                      font-size-sm
                    "
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Người chơi</th>
                        <th>Đã cược</th>
                        <th>Giải thưởng</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="i in 10" :key="i">
                        <td>
                          <img
                            class="user-ico"
                            src="https://static.bc.game/static/media/gold.b279ef91.svg"
                          />
                        </td>
                        <td><i class="fas fa-eye-slash"></i>Hidden</td>
                        <td>
                          <img src="~@/assets/images/home/coin.png" alt="" />
                          37852607636604780
                        </td>
                        <td>
                          <img src="~@/assets/images/home/coin.png" alt="" />
                          0.00040960 <span>(50%)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div> -->
      </div>
    </div>
    <b-modal
      id="modal-notifi"
      title=""
      hide-footer
      body-class="p-0"
      header-class="p-0"
      modal-class="d-flex justify-content-center flex-column m-0"
    >
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <!-- Text slides with image -->
        <b-carousel-slide
          v-for="notifi in NotifileList"
          :key="notifi.image"
          class="img-carousel"
          :img-src="notifi.image"
        ></b-carousel-slide>
      </b-carousel>
    </b-modal>
    <Transaction
      :type="'home'"
      :action="action"
      @changeAction="changeActionParent"
    />
    <b-modal id="modal-updateWallet" hide-footer hide-header>
      <div class="has-close">{{ $t('MainContent.135') }}</div>
      <button class="close-icon" @click="onHideModalUpdateWallet">
        <i class="fas fa-times"></i>
      </button>
      <p class="updateAddress-content">
        {{ $t('MainContent.136') }}.
      </p>
      <p class="updateAddress-content2">
        {{ $t('MainContent.137') }} <br />
        blackchip {{ $t('MainContent.138') }}
      </p>
      <div class="btn-update">
        <b-button @click="showInfoUpdateWallet">{{ $t('MainContent.139') }}</b-button>
      </div>
    </b-modal>
    <InforUser :infoUser="InfoUser" type="updateInfo" />
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';
  import ItemGame from '@/components/game/ItemGame.vue';
  import Transaction from '@/components/modal/Transaction.vue';
  import InforUser from '@/components/modal/InforUser.vue';
  import { mapState, mapMutations, mapGetters } from 'vuex';
  export default {
    components: { ItemGame, VueSlickCarousel, Transaction, InforUser },
    data() {
      return {
        page: '',
        settingHotBanner: {
          arrows: false,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
          autoplay: true,
          responsive: [
            {
              breakpoint: 1699,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
        settingHomeBanner: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
          autoplay: false,
          responsive: [
            {
              breakpoint: 1699,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
        listGameDemo: [
          { tag: 'Top', houseEdge: 1, name: 'Crash', comingSoon: false },
          { tag: 'Top', houseEdge: 1, name: 'Crash', comingSoon: false },
          { tag: 'Top', houseEdge: 1, name: 'Crash', comingSoon: false },
          { tag: 'New', houseEdge: 2, name: 'Crash', comingSoon: false },
          { tag: 'New', houseEdge: 2, name: 'Crash', comingSoon: false },
          { tag: 'Top', houseEdge: 1, name: 'Crash', comingSoon: false },
          { tag: 'Top', houseEdge: 1, name: 'Crash', comingSoon: false },
          { tag: 'Top', houseEdge: 1, name: 'Crash', comingSoon: false },
          { tag: 'Top', houseEdge: 1, name: 'Crash', comingSoon: false },
          { tag: 'Top', houseEdge: 1, name: 'Crash', comingSoon: false },
          { tag: 'Top', houseEdge: 1, name: 'Crash', comingSoon: false },
          { tag: '', houseEdge: 1, name: 'Crash', comingSoon: true },
        ],
        activeCompetiton: 1,
        showIframe: false,
        iframe: {
          src: '',
        },
        slide: 0,
        sliding: null,
        action: 1,
      };
    },
    computed: {
      ...mapState(['isLogin']),
      ...mapGetters({
        listGame: 'dashboard/ListGame',
        NotifileList: 'core/NotificationImage',
        InfoUser: 'dashboard/Info',
      }),
    },
    async created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      await this.$store.dispatch('dashboard/req_getListGame');
      await this.$store.dispatch('core/req_getNotificationImages');
    },
    mounted() {
      // if (this.isLogin) {
      //   if (!this.InfoUser.connectAddressMetamask) {
      //     console.log(this.InfoUser.connectAddressMetamask);
      //     this.$bvModal.show('modal-updateWallet');
      //   }
      // }
    },
    methods: {
      showInfoUpdateWallet() {
        this.$bvModal.show('modal-info-updateInfo');
        this.$bvModal.hide('modal-updateWallet');
      },
      onHideModalUpdateWallet() {
        this.$bvModal.hide('modal-updateWallet');
      },
      handleResize() {
        this.width = window.innerWidth;
        // if (window.innerWidth > 767) {
        //   this.showMenuProfile = false;
        // } else {
        //   this.showMenuProfile = true;
        // }
      },
      showNextHomeBanner() {
        this.$refs.homeBanner.next();
      },
      showPrevHomeBanner() {
        this.$refs.homeBanner.prev();
      },
      chooseGameGetUrl(url) {
        this.iframe.src = url;
        this.iframe.status = true;
        this.$store.commit('getGame', this.iframe);
      },
      changeShowIframeParent(status) {
        this.showIframe = status;
      },
      showSportBookIframe() {
        this.iframe.src = 'https://sportbook.blackchip.net/';
        this.iframe.status = true;
        this.$store.commit('getGame', this.iframe);
      },
      showVM555Iframe() {
        this.iframe.src = 'https://wm111.net/';
        this.iframe.status = true;
        this.$store.commit('getGame', this.iframe);
      },
      onSlideStart() {
        this.sliding = true;
      },
      onSlideEnd() {
        this.sliding = false;
      },
      showRegister() {
        this.$bvModal.show('modal-register-home');
        this.$bvModal.hide('modal-login-home');
      },
      showLogin() {
        this.$store.commit('auth/LOGOUT_SUCCESS');
      },
      changeActionParent(action) {
        this.action = action;
      },
    },
    watch: {
      NotifileList: {
        handler(newVal) {
          if (newVal.length > 0) {
            this.$bvModal.show('modal-notifi');
          }
        },
      },
      // isLogin: {
      //   handler(newVal) {
      //     if (newVal) {
      //       if (!this.InfoUser.connectAddressMetamask) {
      //         console.log(this.InfoUser.connectAddressMetamask);
      //         this.$bvModal.show('modal-updateWallet');
      //       }
      //     }
      //   },
      // },
      InfoUser: {
        handler(newVal) {
          if (!newVal.connectAddressMetamask) {
            if (this.isLogin) {
              this.$bvModal.show('modal-updateWallet');
            }
          }
        },
      },
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
  };
</script>
<style lang="scss">
#modal-notifi{
  .modal-dialog{
    @media (max-width: 575px) {
    margin: 0px;
    }
  }
}
  .home-banner {
    .slick-slider {
      .slick-dots {
        color: white;
        display: flex !important;
        padding-left: 130px !important;
        list-style: none !important;
        margin-top: 10px;
        position: relative;
        z-index: 10;
        @media (max-width: 991px) {
          padding-left: 20px !important;
        }
        @media (max-width: 576px) {
          display: none !important;
        }
        li {
          background-color: #fff;
          border-radius: 50%;
          width: 0.5rem;
          height: 0.5rem;
          margin: 0px 0.5rem 0px 0.5rem;
          background-color: rgb(75, 85, 92);
          position: relative;
          &.slick-active {
            background-color: rgb(93, 160, 0);
            &::after {
              content: '';
              position: absolute;
              width: 1.125rem;
              height: 1.125rem;
              left: -0.3125rem;
              top: -0.3125rem;
              background-color: rgba(144, 155, 135, 0.15);
              border-radius: 1.125rem;
            }
          }
        }
      }
    }
  }
  .competition-content {
    .table {
      background-color: rgb(30, 32, 36);
      border-radius: 1.25rem;
      width: 100%;
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0px;
      border: none;
      min-width: 900px;
      tr {
        border: none;
        &:hover {
          background-color: rgba(189, 193, 197, 0.103);
        }
        th {
          border: none;
          font-weight: normal;
          color: rgba(153, 164, 176, 0.6);
        }
        td {
          border: none;
          color: rgba(153, 164, 176, 0.6);
          &:nth-child(1) {
            img {
              width: auto;
              height: 20px;
            }
            font-weight: 600;
          }
          &:nth-child(2) {
            svg {
              margin-right: 10px;
            }
          }
          &:nth-child(3) {
          }
          &:nth-child(4) {
          }
          &:nth-child(5) {
            color: orange;
            img {
              width: auto;
              height: 20px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .competition-main-table {
    .table {
      background-color: rgb(30, 32, 36);
      border-radius: 1.25rem;
      width: 100%;
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0px;
      border: none;
      tr {
        border: none;
        th {
          border: none;
          font-weight: normal;
          color: rgba(153, 164, 176, 0.6);
        }
        td {
          border: none;
          color: rgba(153, 164, 176, 0.6);
          &:nth-child(1) {
            img {
              width: auto;
              height: 20px;
            }
            font-weight: 600;
          }
          &:nth-child(2) {
            svg {
              margin-right: 10px;
            }
          }
          &:nth-child(3) {
            color: #fff;
            font-weight: bold;
            img {
              width: auto;
              height: 20px;
              margin-right: 5px;
            }
          }
          &:nth-child(4) {
            color: green;
            font-weight: bold;
            img {
              width: auto;
              height: 20px;
              margin-right: 5px;
            }
            span {
              color: #fff;
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  #modal-updateWallet {
    &.modal {
      display: flex !important;
      justify-content: center;
      align-items: flex-start;
      padding-left: 0px !important;
      &::-webkit-scrollbar {
        width: 0px !important;
        height: 0px;
      }
      .modal-content {
        height: auto;
        border-radius: 1.25rem;
        position: relative;
        background-color: rgb(23, 24, 27);
        padding: 1.25rem;
        @media (max-width: 480px) {
          font-size: 14px;
          padding: 0rem;
        }
        .has-close {
          font-size: 1rem;
          height: 2rem;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          font-weight: bold;
          color: #fff;
        }
        .close-icon {
          position: absolute;
          right: 0px;
          top: 0px;
          z-index: 11;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 3.75rem;
          height: 3.75rem;
          border: none;
          background: none;
          color: rgba(153, 164, 176, 0.8);
        }
        .updateAddress-content {
          margin-top: 20px;
          color: #d4fa4d;
          font-weight: bold;
        }
        .updateAddress-content2 {
          color: #d4fa4d;
          font-size: 14px;
        }
        .btn-update {
          text-align: center;

          button {
            box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
            background-color: #5da000;
            background-image: conic-gradient(from 1turn, #458802, #7bc514);
            border-radius: 10px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .home {
    // margin-top: 4rem;
    width: 100%;
    position: relative;
    padding-top: 10px;
    z-index: 2;
    &:after {
      // content: '';
      position: fixed; /* stretch a fixed position to the whole screen */
      top: 0;
      height: 100vh; /* fix for mobile browser address bar appearing disappearing */
      left: 0;
      right: 0;
      z-index: -1; /* needed to keep in the background */
      // background: url('~@/assets/images/bg.jpg') center center;
      // -webkit-background-size: cover;
      // -moz-background-size: cover;
      // -o-background-size: cover;
      // background-size: cover;
      background-color: transparent;
      // -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
      // box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    }
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background-color: rgba(0, 0, 0, 0.1);
      z-index: 0;
    }
    .home-banner {
      height: 100%;
      position: relative;
      max-width: 1368px;
      margin: 0px auto;
      z-index: 2;
      &::before {
        content: '';
        position: absolute;
        right: 0px;
        top: 0px;
        width: calc(50% - 684px);
        bottom: 0px;
        z-index: 9;
        background-image: linear-gradient(
          to left,
          rgb(36, 38, 43) 0%,
          rgb(36, 38, 43) 20%,
          rgba(36, 38, 43, 0) 100%
        );
      }
      &::after {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: calc(50% - 684px);
        bottom: 0px;
        z-index: 9;
        background-image: linear-gradient(
          to right,
          rgb(36, 38, 43) 0%,
          rgb(36, 38, 43) 20%,
          rgba(36, 38, 43, 0) 100%
        );
        z-index: 1;
      }
      @media (max-width: 991px) {
        padding: 0px 10px;
      }
      .item-carousel {
        width: 100%;
        height: 270px;
        display: flex;
        justify-content: flex-start;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          @media (max-width: 991px) {
            background-color: rgba(0, 0, 0, 0.411);
          }
        }
        .img-banner1 {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
          object-position: right center;
        }
        .img-banner {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          // object-fit: cover;
          // object-position: right center;
        }
        .img-banner1 {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
          object-position: right center;
        }
        .text-banner {
          position: absolute;
          top: 20px;
          left: 50px;
          width: 500px;
          @media (max-width: 767px) {
            width: 80%;
            top: 20px;
            left: 20px;
          }
          @media (max-width: 575px) {
            width: calc(100% - 40px);
          }
          p {
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            @media (max-width: 575px) {
              font-size: 18px;
            }
            @media (max-width: 480px) {
              line-height: 120%;
            }
            @media (max-width: 375px) {
              font-size: 15px;
            }
            img {
              height: 25px;
              width: auto;
              @media (max-width: 480px) {
                height: 22px;
              }
              &:nth-child(1) {
                position: absolute;
                top: 60px;
                left: 50px;
                @media (max-width: 767px) {
                  left: 0px;
                }
                @media (max-width: 660px) {
                  left: 50px;
                }
                @media (max-width: 480px) {
                  top: 50px;
                  left: -45px;
                }
              }
              &:nth-child(2) {
                position: absolute;
                top: 35px;
                left: 150px;
                @media (max-width: 767px) {
                  left: 85px;
                }
                @media (max-width: 660px) {
                  left: 150px;
                }
                @media (max-width: 480px) {
                  top: 70px;
                  left: 0px;
                }
              }
              &:nth-child(3) {
                position: absolute;
                top: 85px;
                left: 100px;
                @media (max-width: 767px) {
                  left: 40px;
                }
                @media (max-width: 660px) {
                  left: 80px;
                }
                @media (max-width: 480px) {
                  top: 90px;
                  left: -75px;
                }
              }
            }
          }
        }
        button {
          background: none;
          border: none;
          img {
            height: 70px;
            width: auto;
            &.btn-join1 {
              position: absolute;
              bottom: 0px;
            }
            &.btn-join2 {
              position: absolute;
              bottom: 0px;
              right: 0px;
            }
          }
        }
        .img-banner2 {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
          object-position: center center;
        }
        .text-banner2 {
          position: absolute;
          top: 20px;
          right: 50px;
          width: 500px;
          @media (max-width: 1499px) {
            width: 450px;
          }
          @media (max-width: 767px) {
            width: 80%;
            top: 20px;
            left: 20px;
          }
          @media (max-width: 575px) {
            width: calc(100% - 40px);
          }
          p {
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            @media (max-width: 575px) {
              font-size: 18px;
            }
            @media (max-width: 480px) {
              line-height: 120%;
            }
            @media (max-width: 375px) {
              font-size: 15px;
            }
            span {
              font-size: 24px;
              color: #fbf400;
              font-weight: bold;
              @media (max-width: 575px) {
                font-size: 20px;
              }
              @media (max-width: 375px) {
                font-size: 17px;
              }
            }
          }
        }
        .text-banner3 {
          position: absolute;
          top: 0px;
          left: 50px;
          width: 700px;
          @media (max-width: 1499px) {
            width: 600px;
          }
          @media (max-width: 767px) {
            width: 100%;
            left: 0px;
            padding-left: 20px;
          }
          @media (max-width: 375px) {
            padding-left: 5px;
          }
          img {
            &:nth-child(1) {
              width: 150px;
              height: auto;
              margin-bottom: 10px;
              @media (max-width: 767px) {
                width: 120px;
              }
            }
            &:nth-child(2) {
              width: auto;
              height: 30px;
              @media (max-width: 767px) {
                height: 20px;
              }
            }
            &:nth-child(4) {
              width: 350px;
              height: 2px;
              margin-left: 10px;
              margin-top: 10px;
              margin-bottom: 10px;
              @media (max-width: 767px) {
                margin-top: 5px;
                margin-bottom: 5px;
              }
              @media (max-width: 480px) {
                width: 300px;
                margin-left: 0px;
              }
              @media (max-width: 375px) {
                width: 200px;
              }
            }
          }
          p {
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
          .time {
            width: 100%;
            display: flex;
            align-items: center;
            img {
              &:nth-child(1) {
                width: 6px;
                height: 6px;
                margin-bottom: 0px;
                @media (max-width: 575px) {
                  width: 4px;
                  height: 4px;
                }
              }
              &:nth-child(3) {
                width: auto;
                height: 20px;
                @media (max-width: 575px) {
                  height: 15px;
                }
              }
            }
            span {
              color: #fff;
              font-weight: bold;
              margin: 0px 10px;
              @media (max-width: 575px) {
                font-size: 13px;
                margin: 0px 5px;
              }
            }
          }
          a {
            width: fit-content;
            &:hover {
              .text-banner3-btn {
                img {
                  transform: scale(1.2);
                }
              }
            }
            .text-banner3-btn {
              margin-top: 10px;
              transition: 0.3s;
              outline: none;
              * {
                transition: 0.3s;
                outline: none;
              }
              img {
                width: auto;
                height: 40px;
              }
            }
          }
        }
        .text-banner4 {
          position: absolute;
          top: 0px;
          left: 50px;
          width: 700px;
          @media (max-width: 1499px) {
            width: 650px;
          }
          @media (max-width: 767px) {
            width: 100%;
            left: 0px;
            padding-left: 20px;
          }
          @media (max-width: 375px) {
            padding-left: 5px;
          }
          img {
            &:nth-child(1) {
              width: 150px;
              height: auto;
              margin-bottom: 10px;
              @media (max-width: 767px) {
                width: 120px;
              }
            }
            &:nth-child(2) {
              width: auto;
              height: 30px;
              @media (max-width: 767px) {
                height: 20px;
              }
            }
            &:nth-child(3) {
              width: 200px;
              height: 1px;
              margin-left: 15px;
              margin-top: 5px;
              margin-bottom: 5px;
              @media (max-width: 767px) {
                width: 130px;
              }
              @media (max-width: 480px) {
                width: 300px;
                margin-left: 0px;
              }
              @media (max-width: 375px) {
                width: 200px;
              }
            }
          }
          .time {
            width: 100%;
            display: flex;
            align-items: center;
            color: #fff;
            // flex-wrap: wrap;
            word-wrap: break-word;
            .time-text {
              width: calc(100% - 11px);
            }
            img {
              &:nth-child(1) {
                width: 6px;
                height: 6px;
                margin-bottom: 0px;
                margin-right: 5px;
                @media (max-width: 575px) {
                  width: 4px;
                  height: 4px;
                }
              }
              &:nth-child(2) {
                width: auto;
                height: 20px;
                margin-left: 5px;
                @media (max-width: 575px) {
                  height: 15px;
                }
              }
            }
            span {
              color: #ffe783;
              font-weight: 900;
              margin: 0px 5px;
              font-size: 20px;
              @media (max-width: 575px) {
                font-size: 13px;
              }
            }
          }
          a {
            width: fit-content;
            &:hover {
              .text-banner3-btn {
                img {
                  transform: scale(1.2);
                }
              }
            }
            .text-banner3-btn {
              margin-top: 10px;
              transition: 0.3s;
              outline: none;
              * {
                transition: 0.3s;
                outline: none;
              }
              img {
                width: auto;
                height: 40px;
              }
            }
          }
        }
        .text-banner5 {
          position: absolute;
          top: 0px;
          left: 50px;
          width: 700px;
          display: flex;
          flex-direction: column;
          @media (max-width: 1499px) {
            width: 650px;
          }
          @media (max-width: 767px) {
            width: 100%;
            left: 0px;
            padding-left: 20px;
          }
          @media (max-width: 375px) {
            padding-left: 5px;
          }
          img {
            &:nth-child(1) {
              width: 150px;
              height: auto;
              margin-bottom: 10px;
              @media (max-width: 767px) {
                width: 120px;
              }
            }
            &:nth-child(2) {
              width: 400px;
              height: auto;
              @media (max-width: 767px) {
                width: 280px;
              }
            }
            &:nth-child(3) {
              width: 230px;
              height: 1px;
              margin-left: 15px;
              margin-top: 5px;
              margin-bottom: 5px;
              @media (max-width: 767px) {
                width: 170px;
              }
              @media (max-width: 480px) {
                width: 170px;
                margin-left: 0px;
              }
              @media (max-width: 375px) {
                width: 200px;
              }
            }
          }
          .time {
            width: 100%;
            display: flex;
            align-items: center;
            color: #fff;
            // flex-wrap: wrap;
            word-wrap: break-word;
            img {
              &:nth-child(1) {
                width: 6px;
                height: 6px;
                margin-bottom: 0px;
                margin-right: 5px;
                @media (max-width: 575px) {
                  width: 4px;
                  height: 4px;
                }
              }
              &:nth-child(2) {
                width: auto;
                height: 18px;
                margin-left: 5px;
                @media (max-width: 575px) {
                  height: 15px;
                }
              }
            }
            span {
              color: #ffe783;
              font-weight: bold;
              margin: 0px 5px;
              @media (max-width: 575px) {
                font-size: 13px;
              }
            }
          }
          a {
            width: fit-content;
            &:hover {
              .text-banner3-btn {
                img {
                  transform: scale(1.2);
                }
              }
            }
            .text-banner3-btn {
              margin-top: 10px;
              transition: 0.3s;
              outline: none;
              * {
                transition: 0.3s;
                outline: none;
              }
              img {
                width: auto;
                height: 40px;
              }
            }
          }
        }
        .text-banner6 {
          position: absolute;
          top: 0px;
          left: 50px;
          width: 700px;
          display: flex;
          flex-direction: column;
          @media (max-width: 1499px) {
            width: 650px;
          }
          @media (max-width: 767px) {
            width: 100%;
            left: 0px;
            padding-left: 20px;
          }
          @media (max-width: 375px) {
            padding-left: 5px;
          }
          img {
            &:nth-child(1) {
              width: 150px;
              height: auto;
              margin-bottom: 10px;
              @media (max-width: 767px) {
                width: 120px;
              }
            }
            &:nth-child(2) {
              width: 400px;
              height: auto;
              @media (max-width: 767px) {
                width: 280px;
              }
            }
            &:nth-child(3) {
              width: 230px;
              height: 1px;
              margin-left: 15px;
              margin-top: 5px;
              margin-bottom: 5px;
              @media (max-width: 767px) {
                width: 170px;
              }
              @media (max-width: 480px) {
                width: 170px;
                margin-left: 0px;
              }
              @media (max-width: 375px) {
                width: 200px;
              }
            }
          }
          .time {
            width: 100%;
            display: flex;
            align-items: center;
            color: #fff;
            flex-wrap: wrap;
            img {
              &:nth-child(1) {
                width: 6px;
                height: 6px;
                margin-bottom: 0px;
                margin-right: 5px;
                @media (max-width: 575px) {
                  width: 4px;
                  height: 4px;
                }
              }
              &:nth-child(2) {
                width: auto;
                height: 20px;
                margin-left: 5px;
                @media (max-width: 575px) {
                  height: 15px;
                }
              }
            }
            span {
              color: #ffe783;
              font-weight: bold;
              margin: 0px 5px;
              @media (max-width: 575px) {
                font-size: 13px;
              }
            }
          }
          a {
            .text-banner3-btn {
              margin-top: 10px;
              img {
                width: auto;
                height: 40px;
              }
            }
          }
        }
        .text-banner7 {
          position: absolute;
          top: 50px;
          left: 0px;
          right: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          display: flex;
          a {
            width: fit-content;
            img {
              width: auto;
              height: 200px;
            }
          }
        }
        .text-banner8 {
          position: absolute;
          top: 50px;
          left: 0px;
          right: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          display: flex;
          a {
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              &:nth-child(1) {
                width: auto;
                height: 150px;
                margin-top: 0px;
                margin-left: -20px;
                @media (max-width: 375px) {
                  margin-left: -10px;
                  height: 140px;
                }
              }
              &:nth-child(2) {
                width: 150px;
                height: auto;
                margin-left: -20px;
                margin-top: 10px;
                @media (max-width: 375px) {
                   margin-left: -10px;
                   width: 130px;
                }
              }
            }
          }
        }
      }
    }
    .home-hotbanner {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 1.25rem 0.625rem;
      position: relative;
      .prev-button {
        position: absolute;
        top: -30px;
        right: 200px;
        z-index: 3;
        width: 2.125rem;
        height: 2rem;
        background-color: rgb(30, 32, 36);
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        margin: 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: rgba(153, 164, 176, 0.6);
        border: none;
        @media (max-width: 1199px) {
          width: 1.5rem;
          height: 1.3rem;
          font-size: 14px;
          right: 90px;
        }
        @media (max-width: 576px) {
          top: 0px;
        }
        @media (max-width: 421px) {
          right: 60px;
        }
        @media (max-width: 375px) {
          top: 0px;
        }
        &:hover {
          color: #fff;
          background-color: rgba(75, 72, 72, 0.719);
        }
      }
      .next-button {
        position: absolute;
        top: -30px;
        right: 160px;
        z-index: 3;
        width: 2.125rem;
        height: 2rem;
        background-color: rgb(30, 32, 36);
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: rgba(153, 164, 176, 0.6);
        border: none;
        @media (max-width: 1199px) {
          width: 1.5rem;
          height: 1.3rem;
          font-size: 14px;
          right: 60px;
        }
        @media (max-width: 576px) {
          top: 0px;
        }
        @media (max-width: 421px) {
          right: 30px;
        }
        @media (max-width: 375px) {
          top: 0px;
        }
        &:hover {
          color: #fff;
          background-color: rgba(75, 72, 72, 0.719);
        }
      }
      .home-hotbanner-box {
        max-width: 1368px;
        width: 100%;
        @media (max-width: 480px) {
          margin-top: 20px;
        }
        .hotbanner-title {
          flex: 0 0 auto;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          margin: 0px 0.5rem 1.25rem;
          padding-bottom: 0.625rem;
          border-bottom: 1px solid rgba(228, 229, 230, 0.56);
          font-size: 20px;
          svg {
            color: #fff;
            width: 1.4em;
            height: 1.4em;
          }
          .sub-tit {
            margin: 0px 0.3125rem;
            color: #fff;
            font-weight: bold;
          }
        }
        .pc-wrap {
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          flex-wrap: wrap;
          height: auto;
          position: relative;
          &:hover {
            a {
              cursor: pointer;
              img {
                transform: scale(1.02);
              }
            }
          }
          a {
            display: block;
            position: relative;
            width: 100%;
            height: 220px;
            border-radius: 1.25rem;
            border: none;
            transition: all 0.2s ease 0s;
            padding: 0px 5px;
            * {
              transition: 0.3s;
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 20px;
              object-fit: cover;
              object-position: right center;
            }
          }
          .text-banner3 {
            position: absolute;
            top: 10px;
            right: 20px;
            color: #fff;
            width: 100%;
            max-width: 580px;
            @media (max-width: 640px) {
              left: 10px;
              right: 0px;
              padding-right: 20px;
            }
            p {
              &:nth-child(1) {
                font-size: 20px;
                margin-bottom: 10px;
                line-height: 120%;
                @media (max-width: 640px) {
                  font-size: 16px;
                }
                span {
                  font-weight: bold;
                }
              }
              &:nth-child(2) {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                font-size: 15px;
                margin-bottom: 0px;
                color: #fbf400;
                font-weight: bold;
                @media (max-width: 640px) {
                  font-size: 12px;
                }
                span {
                  width: 48%;
                  &:nth-child(1) {
                    margin-right: 4%;
                  }
                }
              }
              &:nth-child(3) {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                font-size: 15px;
                margin-bottom: 0px;
                color: #fbf400;
                font-weight: bold;
                @media (max-width: 640px) {
                  font-size: 12px;
                }
                span {
                  width: 48%;
                  &:nth-child(1) {
                    margin-right: 4%;
                  }
                }
              }
              &:nth-child(4) {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                font-size: 15px;
                color: #fbf400;
                font-weight: bold;
                @media (max-width: 640px) {
                  font-size: 12px;
                }
                span {
                  width: 48%;
                  &:nth-child(1) {
                    margin-right: 4%;
                  }
                }
              }
            }
          }
          button {
            background: none;
            border: none;
            .btn-join {
              position: absolute;
              bottom: 20px;
              right: 200px;
              height: 60px;
              width: auto;
              @media (max-width: 640px) {
                bottom: 30px;
                right: 130px;
              }
              @media (max-width: 480px) {
                bottom: 30px;
                right: 30px;
              }
              @media (max-width: 375px) {
                bottom: 15px;
                right: 0px;
              }
            }
          }
        }
      }
      .gameList {
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        max-width: 1368px;
        width: 100%;
        .gameItem {
          flex: 1 1 auto;
          width: 15%;
          max-width: 210px;
          //195px
          margin-right: 0.8%;
          margin-left: 0.8%;
          @media (max-width: 1699px) {
            width: 18%;
            max-width: 18%;
          }
          @media (max-width: 1499px) {
            width: 23%;
            max-width: 23%;
          }
          @media (max-width: 991px) {
            width: 23%;
            max-width: 23%;
          }
          @media (max-width: 767px) {
            width: 31%;
            max-width: 31%;
          }
          @media (max-width: 480px) {
            width: 48%;
            max-width: 48%;
          }
        }
      }
      .home-bigwin {
        width: 100%;
        max-width: 1368px;
        .home-bigwin-title {
          color: rgb(255, 255, 255);
          margin: 2.75rem 0px 0.75rem 0.625rem;
          font-weight: 800;
        }
        .home-bigwin-content {
          padding: 1rem;
          background-color: rgb(49, 52, 60);
          border-radius: 1.25rem;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          flex-wrap: wrap;
          @media (max-width: 575px) {
            padding: 5px;
            border-radius: 10px;
          }
          .bigwin-content-left {
            min-width: 30rem;
            align-self: center;
            position: relative;
            flex: 1 1 0%;
            overflow: hidden;
            .info {
              position: relative;
              z-index: 1;
              width: 100%;
              padding: 3rem 0px 2.25rem 1.5rem;
              margin: -1rem 0px;
              @media (max-width: 575px) {
                padding: 10px 0px 25px 10px;
                margin: 0px;
              }
              .tit {
                color: rgb(245, 246, 247);
                font-weight: bold;
                font-size: 1.375rem;
                letter-spacing: -0.73px;
                line-height: 1.75rem;
                @media (max-width: 575px) {
                  font-size: 16px;
                  line-height: 16px;
                  letter-spacing: 0px;
                }
              }
              button {
                margin-top: 3.125rem;
                width: 10.125rem;
                color: rgb(245, 246, 247);
                box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
                background-color: rgb(241, 149, 17);
                background-image: conic-gradient(
                  from 1turn,
                  rgb(241, 149, 17),
                  rgb(246, 199, 34)
                );
                display: block;
                border-radius: 6.25rem;
                height: 3rem;
                font-weight: bold;
                cursor: pointer;
                transition: transform 0.2s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
                border: none;
                @media (max-width: 1299px) {
                  margin-top: 1rem;
                  height: 2rem;
                }
                @media (max-width: 575px) {
                  margin-top: 1rem;
                  width: 7.25rem;
                  height: 2rem;
                }
                .button-inner {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .bg {
              position: absolute;
              z-index: 0;
              height: 120%;
              width: auto;
              bottom: -1.5rem;
              right: -2rem;
              @media (max-width: 1299px) {
                height: 90%;
                bottom: 1rem;
              }
              @media (max-width: 1199px) {
                height: 80%;
                bottom: 1rem;
                right: 5rem;
              }
              @media (max-width: 991px) {
                right: -2rem;
                height: 90%;
              }
              @media (max-width: 767px) {
                height: 100%;
                bottom: 0rem;
              }
              @media (max-width: 575px) {
                height: 80%;
                bottom: 20px;
                right: 10%;
              }
              @media (max-width: 480px) {
                height: 80%;
                bottom: 20px;
                right: 25%;
              }
              @media (max-width: 375px) {
                height: 80%;
                bottom: 20px;
                right: 35%;
              }
            }
          }
          .bigwin-content-right {
            flex: 1 1 0%;
            background-color: rgb(36, 38, 43);
            border-radius: 0.375rem;
            padding: 0.625rem;
            margin: 0px;
            align-self: center;
            position: relative;
            @media (max-width: 1199px) {
              width: 100%;
              overflow-x: auto;
            }
            @media (max-width: 991px) {
              flex: 1 1 auto;
            }
            .winner-img {
              position: absolute;
              left: -0.25rem;
              top: -0.21875rem;
              width: 5rem;
              height: 5rem;
            }
            .rich-item {
              padding: 0.9375rem 4%;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              justify-content: space-between;
              background-color: rgba(49, 52, 60, 0.6);
              min-width: 500px;
              .user-number {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                width: 40%;
                img {
                  margin-right: 10%;
                  width: 1.25rem;
                  height: auto;
                }
                .user-info {
                  flex: 1 1 auto;
                  overflow: hidden;
                  color: rgb(245, 246, 247);
                  font-weight: bold;
                  display: inline-flex;
                  -webkit-box-align: center;
                  align-items: center;
                  .avatar {
                    width: 2rem;
                    height: 2rem;
                    margin-right: 0.75rem;
                  }
                  .name {
                    font-size: 0.9375rem;
                    font-weight: bold;
                    color: rgb(250, 250, 249);
                    flex: 0 0 auto;
                    max-width: 70%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .hidden-name {
                      color: rgb(153, 164, 176);
                      display: flex;
                      -webkit-box-align: center;
                      align-items: center;
                      svg {
                        width: 1em;
                        height: 1em;
                        margin-right: 0.5rem;
                        color: rgb(153, 164, 176);
                      }
                    }
                  }
                }
              }
              .top-winner {
                line-height: 2rem;
                color: rgb(250, 250, 249);
                font-size: 0.75rem;
                width: 4.5rem;
                overflow: hidden;
                text-align: center;
                height: 2rem;
              }
              .rich_select {
                height: 2rem;
                position: relative;
                opacity: 1;
                .select-trigger {
                  width: 10.3125rem;
                  padding: 0px 1.875rem 0px 0.75rem;
                  background: rgb(36, 38, 43);
                  position: relative;
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  overflow: hidden;
                  height: 100%;
                  cursor: pointer;
                  user-select: none;
                  border-radius: 1.25rem;
                  .win {
                    color: rgb(93, 160, 0);
                    font-weight: bold;
                    span {
                      color: rgba(153, 164, 176, 0.7);
                      margin-left: 0.375rem;
                    }
                  }
                  .arrow {
                    width: 2.5rem;
                    height: 100%;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    svg {
                      font-size: 0.625rem;
                      transition: transform 0.5s
                        cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
                      width: 1.4em;
                      height: 1.4em;
                      color: rgba(153, 164, 176, 0.6);
                    }
                  }
                }
              }
            }
          }
        }
      }
      .competition {
        width: 100%;
        max-width: 1368px;
        margin-top: 32px;
        .competition-title {
          color: rgb(245, 246, 247);
          font-size: 0.875rem;
          margin-left: 0.625rem;
          margin-bottom: 0.75rem;
          max-width: 12.5rem;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          height: 2.25rem;
          position: relative;
          border-radius: 1.125rem;
          background-color: rgba(49, 52, 60, 0.7);
          .competition-title-button {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            flex: 1 1 0%;
            height: 100%;
            cursor: pointer;
            white-space: nowrap;
            width: 100%;
            z-index: 1;
            &.activeTitle {
              color: rgb(245, 246, 247);
              font-weight: bold;
              border-radius: 1.125rem;
              background-image: linear-gradient(
                to left,
                rgb(85, 89, 102),
                rgb(85, 89, 102),
                rgb(88, 174, 20)
              );
              height: 100%;
              transform: translateZ(0px);
              box-shadow: rgb(0 0 0 / 14%) 0px 0px 0.3125rem;
              transition: left 0.2s ease-out 0s, right 0.3s ease-out 0s;
            }
          }
        }
        .competition-content {
          .competition-main {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            flex-wrap: wrap;
            background: rgb(30, 32, 36);
            padding: 0px 1rem 1rem 0px;
            border-radius: 0px 0px 1.25rem 1.25rem;
            .competition-main-left {
              flex: 1 1 0%;
              margin: 1rem 0px 0px 1rem;
              padding-top: 1.5rem;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              width: 100%;
              @media (max-width: 1099px) {
                padding-top: 0rem;
              }
              @media (max-width: 991px) {
                justify-content: center;
              }
              @media (max-width: 480px) {
                flex-direction: column;
              }
              .trophy {
                width: 10rem;
                margin-right: 1rem;
                @media (max-width: 480px) {
                  margin-right: 0rem;
                }
                img {
                  width: 100%;
                }
              }
              .trophy-content {
                .type {
                  width: 5.5625rem;
                  height: 1.3125rem;
                  line-height: 1.3125rem;
                  font-size: 1rem;
                  font-weight: 600;
                  margin-top: 1rem;
                  text-align: center;
                  color: rgb(93, 160, 0);
                  margin-left: 0.625rem;
                  background-image: url(https://static.bc.game/static/media/grass_left.c0bdebd7.svg),
                    url(https://static.bc.game/static/media/grass_right.3727bc58.svg);
                  background-repeat: no-repeat;
                  background-position: left center, right center;
                  background-size: auto 100%;
                  text-transform: capitalize;
                  @media (max-width: 480px) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-left: 0rem;
                  }
                }
                .title {
                  font-size: 1.125rem;
                  font-weight: 600;
                  line-height: 1.56;
                  color: rgb(245, 246, 247);
                  margin: 0px 0px 0.75rem 0.625rem;
                  @media (max-width: 1099px) {
                    font-size: 16px;
                  }
                  @media (max-width: 480px) {
                    text-align: center;
                    margin: 10px 0px;
                  }
                }
                .prize-amount {
                  height: 3rem;
                  width: 16.375rem;
                  padding: 0.625rem 1.25rem;
                  border-radius: 0.625rem;
                  background-color: rgba(49, 52, 60, 0.6);
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  font-size: 1.5rem;
                  font-weight: 600;
                  color: rgb(246, 199, 34);
                  letter-spacing: -1px;
                  @media (max-width: 1099px) {
                    font-size: 16px;
                  }
                  .currency-icon {
                    width: 1.25rem;
                    height: 1.25rem;
                    margin-right: 0.5rem;
                  }
                  .currency-name {
                    color: rgb(245, 246, 247);
                    margin-left: 0.5rem;
                  }
                }
              }
            }
            .competition-main-right {
              flex: 4 1 0%;
              display: flex;
              flex-direction: column;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              height: 9.5rem;
              border-radius: 0.625rem;
              background: rgba(49, 52, 60, 0.6);
              margin: 1rem 0px 0px 1rem;
              .text {
                max-width: 19.125rem;
                margin-bottom: 0.75rem;
                font-size: 0.75rem;
                opacity: 0.6;
                color: rgb(153, 164, 176);
                text-align: center;
              }
              .breathe {
                width: 15.625rem;
                height: 3.5rem;
                border-radius: 1.75rem;
                margin: 0px auto;
                color: rgb(245, 246, 247);
                box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
                background-color: rgb(93, 160, 0);
                background-image: conic-gradient(
                  from 1turn,
                  rgb(69, 136, 2),
                  rgb(123, 197, 20)
                );
                font-weight: bold;
                cursor: pointer;
                transition: transform 0.2s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
                display: block;
                border: none;
                .button-inner {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          .competition-main-table {
            margin-top: 20px;
            width: 100%;
            .title {
              position: relative;
              text-align: center;
              height: 100%;
              color: rgba(153, 164, 176, 0.6);
              font-size: 0.75rem;
              background-color: rgb(30, 32, 36);
              padding: 10px 0px;
              border-radius: 1.75rem;
              margin-bottom: 10px;
              .active-icon {
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                .label {
                  color: rgb(255, 255, 255);
                  margin-left: 0.5rem;
                  position: relative;
                  z-index: 2;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
